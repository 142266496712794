export default {
  'loading': 'Loading...',
  'text-label': {
    'enumeration': {
      'separator': ', ',
      'last-separator': 'and'
    }
  },
  'modal': {
    'continue': 'Continue',
    'cancel': 'Cancel',
    'close': 'Close',
    'yes': 'Yes',
    'no': 'No'
  },
  'dropdown': {
    'close': 'Close'
  },
  'form': {
    'title': {
      'options': {
        'mrs': 'Mrs',
        'mr': 'Mr',
        'ms': 'Ms'
      }
    },
    'gender': {
      'options': {
        'male': 'Male',
        'female': 'Female'
      }
    },
    'code-type-select': {
      'options': {
        'postal-code': 'Postal code',
        'eir-code': 'EIRcode'
      }
    }
  },
  'feedback': {
    'title': 'An error has occured',
    'close-button': 'Close feedback message'
  },
  'counties': {
    'IE-CW': 'Carlow',
    'IE-CN': 'Cavan',
    'IE-CE': 'Clare',
    'IE-C': 'Connaught',
    'IE-CO': 'Cork',
    'IE-DL': 'Donegal',
    'IE-D': 'Dublin',
    'IE-G': 'Galway',
    'IE-KY': 'Kerry',
    'IE-KE': 'Kildare',
    'IE-KK': 'Kilkenny',
    'IE-LS': 'Laois',
    'IE-L': 'Leinster',
    'IE-LM': 'Leitrim',
    'IE-LK': 'Limerick',
    'IE-LD': 'Longford',
    'IE-LH': 'Louth',
    'IE-MO': 'Mayo',
    'IE-MH': 'Meath',
    'IE-MN': 'Monaghan',
    'IE-M': 'Munster',
    'IE-OY': 'Offaly',
    'IE-RN': 'Roscommon',
    'IE-SO': 'Sligo',
    'IE-TA': 'Tipperary',
    'IE-U': 'Ulster',
    'IE-WD': 'Waterford',
    'IE-WH': 'Westmeath',
    'IE-WX': 'Wexford',
    'IE-WW': 'Wicklow'
  },
  'title': 'IRISH-RAIL',
  'meta': {
    'description': 'IRISH-RAIL'
  },
  'main-menu': {
    'tickets': 'Tickets',
    'reservations': 'Reservations',
    'aftersales': {
      'title': 'After sales',
      'bookings': 'Bookings',
      'read-tickets': 'Read ticket',
      'voucher-to-cash': 'Voucher to Cash'
    },
    'maintenance': {
      'title': 'Maintenance',
      'test-cfd': 'Test cfd',
      'test-magstripe': 'Test magstripe',
      'test-thermal-printer': 'Test thermal printer',
      'set-darkness': 'Adjust Darkness Magstripe Printer'
    },
    'leap': 'Leap',
    'shift': 'Shift'
  },
  'agent': {
    'login': {
      'form': {
        'button': 'Login',
        'username': {
          'label': 'Username',
          'placeholder': 'Username',
          'is-required': 'Username is required'
        },
        'password': {
          'label': 'Password',
          'placeholder': 'Password',
          'is-required': 'Password is required'
        }
      },
      'invalid-roles': 'You are not authorized to use the BOM.',
      'configured-incorrect': 'Your account is configured incorrect, please contact a system administrator.',
      'error': 'Unknown service error occurred.'
    },
    'shift': {
      'password-will-expire-soon': 'Warning: your password is going to expire in {days} days. Please set a new password',
      'start': {
        'title': 'Start a shift',
        'description': 'Start a shift',
        'information': 'There is no active shift on your account. Please start a shift.',
        'form': {
          'button': 'Start shift',
          'amount': {
            'label': 'Start amount (EUR)',
            'placeholder': '0.00'
          }
        },
        'logout': 'Logout'
      },
      'start-error-modal': {
        'title': 'Error starting the shift',
        'updating-leap-cd-failed': 'Updating of the Leap CD set failed.',
        'invalid-nadman-device-id': 'The deviceId (machine_name) could not be retrieved from the NAD Manager device, please check your terminal.',
        'invalid-nadman-location': 'The location (station_id) could not be retrieved from the NAD Manager device, please check your terminal.',
        'logout': 'Logout'
      },
      'resume': {
        'title': 'Resume your shift',
        'description': 'Resume your shift',
        'information': 'You have a running shift started on this machine on {date} at {time}. Please resume your shift.',
        'button-resume': 'Resume shift',
        'button-end': 'End shift',
        'button-logout': 'Logout'
      },
      'end': {
        'title': 'End your shift',
        'description': 'End your shift',
        'information': 'You have a running shift started on machine {deviceId} on {date} at {time}. End your shift before starting a new shift on this machine.',
        'device-unknown': '(unknown)',
        'button-end': 'End shift',
        'button-logout': 'Logout',
        'receipt-printer-offline': 'Please note that the receipt printer is offline, so printing receipts is currently not possible'
      }
    }
  },
  'offline': {
    'title': 'Offline',
    'title-fetching-ticket': 'Fetching ticket',
    'retry-ping': 'Internet connection will be tested in {seconds}s',
    'retry-action': 'Action will be retried in {seconds}s'
  },
  'messages': {
    'agent-bad-credentials': 'The username and password you entered don\'t match. Please try again. If you continue to experience issues, please contact your supervisor.',
    'agent-change-password-wrong-current-password': 'The provided current password does not match the password of the currently logged in agent',
    'agent-change-password-permission-denied': 'You do not have permission to change your password',
    'agent-blocked-password': 'Too many failed login attempts! Please reset your password.',
    'agent-expired-password': 'Your password has expired. Please contact your system administrator to reset your password. Once your password has been reset by the system administrator, you will receive an email with your new password. ',
    'agent-inactive': 'This account is blocked!',
    'agent-with-given-username-already-exists': 'An agent with given username already exists.',
    'agent-reset-password-not-found': 'No agent was found for given username/email combination',
    'agent-change-password-weak': 'Entered password is weak. Requires a minimum of 8 characters and should contain at least one upper case letter, one digit and one symbol.',
    'agent-not-allowed-multiple-devices': 'You are already logged in on another device. Please logout on other device and try again.',
    'session-expired': 'Your session has expired. You will need to login again to be able to keep using the application.',
    'voucher-not-found': 'Could not find a voucher with the given code',
    'voucher-already-added': 'Voucher was already added',
    'voucher-to-remove-does-not-exists': 'Voucher to remove does not exists',
    'voucher-not-available-for-travel-day': 'Voucher not available for selected travel day',
    'voucher-already-used': 'This voucher is already used once',
    'voucher-not-allowed': 'You are not entitled to use this voucher',
    'voucher-restricted': 'Voucher is not valid for this payment',
    'voucher-amount-not-sufficient': 'The remaining voucher amount is not sufficient for the total booking amount',
    'voucher-not-available-in-booking-currency': 'Voucher not available in booking currency',
    'voucher-no-refund-payment-for-refund-voucher': 'The booking does not have any unprocessed refund payments, so no refund voucher can be created',
    'voucher-no-customer-information-for-refund-voucher': 'The booking does not contain any customer information to send the refund voucher to',
    'voucher-not-for-sale-today': 'This voucher is not available for sale today.',
    'voucher-not-available-for-travelling-on-give-date': 'This voucher is not available for travelling on given date.',
    'voucher-full-amount-used': 'This voucher\'s full amount is used.',
    'voucher-used-by-customer': 'This voucher is already used',
    'voucher-not-available-for-any-service': 'This voucher cannot be used on this service or booking.',
    'voucher-not-available-for-booking': 'This voucher is not available for this booking.',
    'voucher-email-validation-failed': 'Customer email validation is failed for this voucher.',
    'voucher-not-available-for-total': 'This voucher is not available for total booking amount.',
    'voucher-sales-time-restriction': 'This voucher has a sales time restriction',
    'voucher-not-authorised-for-sales-channel': 'Sales channel is not authorised to use this voucher',
    'voucher-restricted-to-one-way-trip': 'Voucher can only be used for bookings with one-way trip',
    'payment-no-eligible-payments-exists': 'No eligible payments were found on requested booking for processing refunds',
    'booking-reached-resend-limit': 'Booking reached resend limit',
    'booking-is-not-found': 'The booking could not be found or has expired.',
    'booking-is-expired': 'The booking could not be found or has expired.',
    'booking-create-unknown-error': 'An unknown error occurred during the creation of the booking, please try again or contact the system administrator',
    'booking-create-not-available': 'Your requested booking could not be made; a part of your selection is currently no (longer) available for booking. You are redirected to the first step of the booking page in order to review the renewed offer.',
    'booking-create-out-of-stock': 'Your requested booking could not be made; a part of your selection is currently no longer available for booking.',
    'booking-search-not-specific': 'Customer or passenger search is not specific enough and produces too many results; booking search aborted.',
    'agent-crm-access-required-to-view-customers': 'You do not have permission to search for customers. Please contact the system administrator.'
  },
  'progress-navigation': {
    'previous': 'Previous step',
    'next': 'Next step',
    'confirm': 'Confirm'
  },
  'application-error': {
    'header': 'An error has occurred',
    'description': 'An error has occurred',
    'known-error': 'A technical error {errorCode} has occurred which prevents the system to provide you with the requested page. Please contact your designated IÉ contact person if this issue persists',
    'unknown-error': 'A technical error has occurred which prevents the system to provide you with the requested page. Please contact your designated IÉ contact person if this issue persists',
    'go-back-button': 'Go back to previous page',
    'page-not-found': 'Page not found'
  },
  'tickets-error': {
    'message': 'An unexpected error has occurred. Please click below to restart your process.',
    'go-back-button': 'Restart process'
  },
  'booking-confirmation-error': {
    'message': 'An error has occurred when confirming the booking with reference {bookingNumber}. Please click below to go to the after sales page to manually confirm or cancel the booking..',
    'go-back-button': 'Go to booking after sales page'
  },
  'booking-summary': {
    'action': {
      'next': 'Next step',
      'skip': 'Skip',
      'book-tickets': 'Book tickets',
      'toggle': 'Show specification details',
      'confirm': 'Confirm',
      'confirm-sub-refund': 'And refund {total}',
      'confirm-sub-pay': 'And pay {amount}'
    },
    'price-summary': {
      'heading': 'Passengers',
      'passenger-amount': '{passengersCount} {passengersCount, plural, one {passenger} other {passengers}}',
      'passenger-type-count': '{count}x',
      'passenger-type': '{type, select, A {Adult} C {Child} S {Student}}',
      'change-journey': 'Change journey',
      'fee-heading': 'Applicable fees:',
      'rebooking-fee': 'Fees applicable to this transaction',
      'total-price': 'Total',
      'total-price-seatchange': 'Seat change fees',
      'total-price-additional-products': 'Selected extras',
      'total-price-namechange': 'Name change fees',
      'exchanged-tickets-value': 'Value of exchanged tickets'
    },
    'travel-compact': {
      'select-journey': 'Select {direction, select, outbound {outward} inbound {return}} journey'
    },
    'travel-details': {
      'segment-set-header': '{direction, select, outbound {Outward} inbound {Return}} journey',
      'passenger-type': '({type, select, A {Adult} C {Child} S {Student}})',
      'multiplier': '{count}x'
    }
  },
  'shift-modal': {
    'title': 'Shift information',
    'sub-title': 'Your shift has been ended (remotly). Please print your shift report before starting a new shift.',
    'saved-successful': 'Note saved successfully',
    'shift-ended-remotely': 'Your shift has been ended remotely',
    'form': {
      'account-name': {
        'label': 'Account'
      },
      'shift-start': {
        'label': 'Shift started at'
      },
      'shift-notes': {
        'label': 'Note',
        'placeholder': 'Type your note here'
      },
      'logout': 'Logout',
      'change-password': 'Change password',
      'save-note': 'Save note',
      'end-shift': 'End shift',
      'print-shift-report': 'Print report'
    }
  },
  'change-password': {
    'title': 'Change password',
    'success': 'Your password is successfully changed',
    'form': {
      'submit': 'Change password',
      'return': 'Back to shift information',
      'password-strength-indicator': {
        'requirements': 'Your password should consist of a minimum of',
        'min-8-characters': 'Minimum of 8 characters',
        'one-digit': 'One digit',
        'one-symbol': 'One symbol',
        'one-uppercase': 'One upper case character',
        'password-is-equal': 'Repeated password must be equal to the new password'
      },
      'current-password': {
        'label': 'Current password',
        'placeholder': 'Current password',
        'is-required': 'This field is required'
      },
      'new-password': {
        'label': 'New password',
        'placeholder': 'New password',
        'is-required': 'This field is required',
        'is-not-strong-enough': 'Password is not strong enough'
      },
      'repeat-new-password': {
        'label': 'Repeat new password',
        'placeholder': 'Repeat new password',
        'is-required': 'This field is required',
        'is-not-repeated': 'Passwords do not match'
      }
    }
  },
  'edit-shortcut': {
    'title': 'Edit shortcut ({hotkey})',
    'save': 'Save',
    'saved-successful': 'Shortcut saved successful',
    'fill-in-all-fields': 'Please fill in the fields on the left side to see a list of matching products',
    'no-products-found': 'No products found',
    'search-products-button': 'Search products',
    'discount-card-feedback': 'Press save to store this shortcut as a Discount Card shortcut',
    'form': {
      'product-family': {
        'label': 'Product family',
        'placeholder': 'Select product family',
        'is-required': 'Is required'
      },
      'origin': {
        'label': 'Origin',
        'placeholder': 'Origin',
        'is-required': 'Is Required',
        'invalid-station': 'Invalid station',
        'empty-list-label': 'There are no stations',
        'empty-filter-label': 'Your filter does not return any stations',
        'stations-are-equal': 'Origin and destination stations cannot be equal'
      },
      'destination': {
        'label': 'Destination',
        'placeholder': 'Destination',
        'is-required': 'Is Required',
        'invalid-station': 'Invalid station',
        'empty-list-label': 'There are no stations',
        'empty-filter-label': 'Your filter does not return any stations',
        'stations-are-equal': 'Origin and destination stations cannot be equal'
      },
      'date-interval': {
        'label': 'Date interval<br/>(0 = today)',
        'placeholder': 'Date',
        'add': 'Add',
        'subtract': 'Subtract'
      },
      'product': {
        'refresh': 'Refresh',
        'placeholder': 'Product',
        'label': 'Product'
      },
      'product-filter': {
        'placeholder': 'Filter product'
      },
      'adults': {
        'label': 'Adults',
        'placeholder': 'Adults',
        'add': 'Add',
        'subtract': 'Subtract'
      },
      'children': {
        'label': 'Children',
        'placeholder': 'Children',
        'add': 'Add',
        'subtract': 'Subtract'
      },
      'students': {
        'label': 'Students',
        'placeholder': 'Students',
        'add': 'Add',
        'subtract': 'Subtract'
      },
      'discount-card': {
        'label': 'Card'
      }
    }
  },
  'date-input-field': {
    'back-button-title': 'Back',
    'next-button-title': 'Next',
    'form': {
      'text-field': {
        'is-required': 'Is Required',
        'date-is-in-the-past': 'Date cannot be in the past'
      }
    }
  },
  'product-search': {
    'max-passengers-warning': {
      'title': 'Max Passengers',
      'information': 'It is not possible to book for more than {maxPassengers} passengers.'
    },
    'different-passengers-number-warning': {
      'title': 'Passenger amount or type do not match',
      'information': 'Amount of passengers or passenger types do not match with the provisional booking'
    },
    'before-day-current-month-warning': {
      'title': 'Not allowed to book this date',
      'information': 'Not allowed to book this date for this product family due to product family restrictions, please select a date before {beforeDayCurrentMonth} of this month.'
    },
    'leap-option-check-balance': {
      'title': {
        'top-up': 'Leap top up',
        'add-product': 'Leap add product'
      },
      'information': 'Please prepare to present the card on the reader to check current purse balance.',
      'button': {
        'continue': 'Proceed'
      }
    },
    'cart-zero-value-warning': {
      'title': 'Shopping cart value of EUR 0,-',
      'information': 'Your shopping cart currently has a value of EUR 0,-. Do you wish to proceed fulfillment?',
      'button': {
        'continue': 'Yes',
        'cancel': 'No'
      }
    },
    'one-leap-product-booking': {
      'title': 'Booking can only contain 1 Leap product',
      'information': 'Your booking has a Leap fulfillment, this causes that you can have only one product in your booking'
    },
    'one-gift-voucher-booking': {
      'title': 'Booking can only contain 1 gift voucher',
      'information': 'Your booking has a gift voucher fulfillment, this causes that you can have only one product in your booking'
    },
    'amount-input-field': {
      'add-button-title': 'Add',
      'subtract-button-title': 'Subtract'
    },
    'passenger-details-button': 'Passenger details',
    'passenger-details': {
      'title': 'Passenger details',
      'wheelchair': 'Wheelchair',
      'passenger': 'Passenger',
      'seat-only': 'Seat Only',
      'discount-card': 'Discount Card',
      'discount-card-number': 'Discount Card Number',
      'welfare-number': 'Social Welfare number',
      'student-card': 'Student card number',
      'discount-card-types': {
        'welfare-card': 'Welfare Card',
        'student-card': 'Student Card',
        'privilege-card': 'Privilege',
        'no-card': 'No Card'
      },
      'save': 'Save',
      'form': {
        'wheelchair': {
          'passenger-number': '{number}',
          'passenger-type': '({type, select, A {Adult} C {Child} S {Student}})',
          'placeholder': 'Wheelchair'
        },
        'seat-only': {
          'placeholder': 'Seat Only'
        },
        'welfare-number': {
          'placeholder': 'Social Welfare number'
        },
        'student-card': {
          'placeholder': 'Student card number'
        },
        'discount-card-number': {
          'placeholder': 'Discount Card Number'
        },
        'discount-card': {
          'placeholder': 'Discount Card'
        }
      }
    },
    'repeat-previous-button': 'Repeat last booking',
    'search-button': 'Search',
    'form': {
      'origin': {
        'label': 'Origin',
        'placeholder': 'Origin',
        'is-required': 'Is Required',
        'invalid-station': 'Invalid station',
        'empty-list-label': 'There are no stations',
        'empty-filter-label': 'Your filter does not return any stations',
        'stations-are-equal': 'Origin and destination stations cannot be equal'
      },
      'destination': {
        'label': 'Destination',
        'placeholder': 'Destination',
        'is-required': 'Is Required',
        'invalid-station': 'Invalid station',
        'empty-list-label': 'There are no stations',
        'empty-filter-label': 'Your filter does not return any stations',
        'stations-are-equal': 'Origin and destination stations cannot be equal'
      },
      'valid-from': {
        'title': 'Valid from',
        'label': 'Valid from',
        'placeholder': 'Valid from',
        'is-required': 'Is Required',
        'date-is-in-the-past': 'Date cannot be in the past'
      },
      'adults': {
        'label': 'Adults',
        'placeholder': 'Adults',
        'invalid-value': 'At least one adult should be part of the journey',
        'add': 'Add adult',
        'subtract': 'Subtract adult'
      },
      'children': {
        'label': 'Children',
        'placeholder': 'Children',
        'add': 'Add child',
        'subtract': 'Subtract child'
      },
      'students': {
        'label': 'Students',
        'placeholder': 'Students',
        'add': 'Add child',
        'subtract': 'Subtract child'
      }
    }
  },
  'journey-search': {
    'max-passengers-warning': {
      'title': 'Max Passengers',
      'information': 'It is not possible to book for more than {maxPassengers} passengers.'
    },
    'amount-input-field': {
      'add-button-title': 'Add',
      'subtract-button-title': 'Subtract'
    },
    'date-input-field': {
      'modal-title': 'Valid from',
      'back-button-title': 'Back',
      'next-button-title': 'Next'
    },
    'passenger-details-button': 'Passenger details',
    'passenger-details': {
      'title': 'Passenger details',
      'passenger': 'Passenger',
      'wheelchair': 'Wheelchair',
      'discount-card-number': 'Discount Card Number',
      'discount-card': 'Discount Card',
      'discount-card-types': {
        'welfare-card': 'Welfare Card',
        'student-card': 'Student Card',
        'privilege-card': 'Privilege',
        'no-card': 'No Card'
      },
      'save': 'Save',
      'form': {
        'wheelchair': {
          'passenger-number': '{number}',
          'passenger-type': '({type, select, A {Adult} C {Child} S {Student}})',
          'placeholder': 'Wheelchair'
        },
        'discount-card-number': {
          'placeholder': 'Discount Card Number'
        },
        'discount-card': {
          'placeholder': 'Discount Card'
        }
      }
    },
    'repeat-previous-button': 'Repeat last booking',
    'search-button': 'Search',
    'form': {
      'bike-availability': {
        'placeholder': 'Show bike availability',
        'label': 'Show bike availability'
      },
      'origin': {
        'label': 'Origin',
        'placeholder': 'Origin',
        'is-required': 'Is Required',
        'invalid-station': 'Invalid station',
        'empty-list-label': 'There are no stations',
        'empty-filter-label': 'Your filter does not return any stations',
        'stations-are-equal': 'Origin and destination stations cannot be equal'
      },
      'destination': {
        'label': 'Destination',
        'placeholder': 'Destination',
        'is-required': 'Is Required',
        'invalid-station': 'Invalid station',
        'empty-list-label': 'There are no stations',
        'empty-filter-label': 'Your filter does not return any stations',
        'stations-are-equal': 'Origin and destination stations cannot be equal'
      },
      'outbound-date': {
        'title': 'Single',
        'label': 'Single',
        'placeholder': 'Single',
        'is-required': 'Is Required',
        'date-is-in-the-past': 'Date cannot be in the past'
      },
      'outbound-time': {
        'title': 'Single time',
        'label': 'Single time',
        'placeholder': 'All day'
      },
      'inbound-date': {
        'title': 'Return',
        'label': 'Return',
        'placeholder': 'Return',
        'is-required': 'Is Required',
        'date-is-in-the-past': 'Date cannot be in the past'
      },
      'inbound-time': {
        'title': 'Return time',
        'label': 'Return time',
        'placeholder': 'All day'
      },
      'times': {
        'filter-one': 'Before 10AM',
        'filter-two': '9AM to 3PM',
        'filter-three': '2PM to 8PM',
        'filter-four': '6PM to 12PM'
      },
      'adults': {
        'label': 'Adults',
        'placeholder': 'Adults',
        'invalid-value': 'At least one adult should be part of the journey',
        'add': 'Add adult',
        'subtract': 'Subtract adult'
      },
      'children': {
        'label': 'Children',
        'placeholder': 'Children',
        'add': 'Add child',
        'subtract': 'Subtract child'
      },
      'students': {
        'label': 'Students',
        'placeholder': 'Students',
        'add': 'Add child',
        'subtract': 'Subtract child'
      }
    }
  },
  'journey-result-set': {
    'header': {
      'departure': 'Departure',
      'arrival': 'Arrival',
      'travel-time': 'Duration'
    },
    'route': {
      'travel-time': '{hours}h {minutes}m',
      'transfers': '({transferCount} {transferCount, plural, one {transfer} other {transfer}})',
      'no-bundles': 'If this train is an <a href="http://www.irishrail.ie/about-us/intercity" target="_blank" ref="noopener">InterCity Service</a> it is sold out. Please select an alternative. To purchase <a href="http://www.irishrail.ie/about-us/dart-commuter" target="_blank" ref="noopener">DART or Commuter tickets</a>, please go to your local station.',
      'show-details': 'Show details',
      'bundle': {
        'sold-out': 'Sold out',
        'filtered-out': 'No bike availability',
        'availability': '{availability, plural, =0 {no seats available} one {1 seat left} other {{availability} seats available}}'
      },
      'no-routes-available': 'There are no services available between the origin and destination you have selected. Please try again. If this problem persists, please <a href="http://www.irishrail.ie/contact-us/report-website-error" target="_blank" ref="noopener">contact us</a>'
    },
    'date-switcher': {
      'previous-date-set': 'Previous date set',
      'next-date-set': 'Next date set',
      'price-from': 'Price from'
    },
    'journey-details': {
      'arrival': 'Arr',
      'departure': 'Dep',
      'change': 'Change',
      'transfer-time': '(approx {time})',
      'close': 'Close',
      'toggle': 'Toggle'
    },
    'inbound-before-outbound': {
      'header': 'Return trip departure before outbound trip arrival',
      'content': 'You have selected a return trip which departs before the arrival of your outbound trip. This is not possible. Please select another offer.'
    },
    'pagination': {
      'earlier': 'Earlier trains',
      'later': 'Later trains'
    },
    'travel-set': {
      'date-switcher': {
        'from-label': 'From label',
        'previous-day': 'Previous day',
        'next-day': 'Next day'
      }
    }
  },
  'review-payment': {
    'title': 'Review payment',
    'description': 'Review payment description',
    'total-amount': 'Total amount',
    'to-be-paid': 'Amount to be paid',
    'remove-voucher': 'Remove voucher',
    'payment-methods-title': 'Payment method',
    'payment-methods-description': 'Payment method description',
    'payment-description': '{method, select, CASH {Cash} BOM_PED {Credit/debit card} voucher {Voucher {code}} other {Unknown:}}'
  },
  'seat-selection': {
    'title': 'Seat selection',
    'description': 'You can automatically assign a seat or manually',
    'buttons': {
      'automatic-seat-selection': 'Automatic seat selection',
      'manual-seat-selection': 'Manual seat selection'
    }
  },
  'allocated-seats': {
    'title': 'Seat selection',
    'description': 'Seat selection description',
    'information': 'Seat selection is optional. If not manually selected, seats will be assigned',
    'journey': {
      'outbound': 'Outward journey',
      'inbound': 'Return journey',
      'travel-date': 'on {date}'
    },
    'leg': {
      'departure-time': 'at {time}',
      'seat': '{carriage}{requireSeparator, select, yes {/} other {}}{seat}',
      'seat-separator': ', ',
      'seat-line': '{count} seats selected ({seats})',
      'select': 'Select',
      'change': 'Change'
    },
    'warning-no-seat-selected': 'Seats will be assigned automatically',
    'warning-seat-selection-not-available': 'Seat selection is not available on this segment'
  },
  'additional-products': {
    'title': 'Additional products',
    'no-products-found': 'Sorry, there are no additional products available on this trip',
    'description': 'Additional products description',
    'cheapest-price': 'from {price, number, ::currency/EUR}',
    'price': '{price, number, ::currency/EUR}',
    'passenger': 'Passenger {number}',
    'passenger-name': '{firstName} {lastName}',
    'passenger-type': '({type, select, S {student} C {child under 16} other {adult}})',
    'passenger-price': '{price, number, ::currency/EUR}',
    'already-booked': 'Already booked',
    'bike-warning': 'Bike is not available for both directions',
    'no-booking-fulfillment-method': 'This additional product is not compatible for this booking',
    'journey': {
      'outbound': 'Outward journey',
      'inbound': 'Return journey',
      'travel-date': 'on {date}'
    }
  },
  'segment-block': {
    'departure-time': 'at {time}',
    'departure-date': 'on {date}',
    'arrival-time': 'at {time}',
    'arrival-date': 'on {date}'
  },
  'products-result': {
    'passenger-count': '{adults, select, 0 {} other {{adults}A}} {students, select, 0 {} other {{students}S}} {children, select, 0 {} other {{children}C}}',
    'more-button': 'More',
    'title-more-modal': 'More products',
    'no-products-found': 'No products match your search criteria.'
  },
  'fulfillment-methods': {
    'BM_MS': 'Magstripe',
    'BM_B': 'Barcode',
    'BM_L': 'Leap',
    'BM_LT': 'Leap top up',
    'more-button': 'More',
    'title-more-modal': 'More Fulfillment Methods'
  },
  'payment-methods': {
    'NONE_CASH': {
      'name': 'Cash',
      'modal-title': 'Cash'
    },
    'voucher': {
      'name': 'Voucher',
      'modal-title': 'Voucher'
    },
    'NONE_BOM_PED': {
      'name': 'Credit / Debit card',
      'modal-title': 'Credit / Debit card'
    },
    'more-button': 'More',
    'title-more-modal': 'More Payment Methods'
  },
  'cash-payment': {
    'form': {
      'amount-to-be-paid': {
        'label': 'Amount to be paid',
        'price-GBP': '({price, number, ::currency/GBP})'
      },
      'amount-received': {
        'label': 'Amount received',
        'placeholder': 'Amount received'
      },
      'amount-change': {
        'label': 'Change'
      },
      'payment-received': {
        'label': 'Payment received'
      },
      'currency-selector': {
        'label': '',
        'values': {
          'currency-eur': 'EUR',
          'currency-gbp': 'GBP'
        }
      }
    },
    'feedback': {
      'api-error': 'Error: Payment can not be added to booking, please retry'
    }
  },
  'credit-debit-payment': {
    'form': {
      'authorization-code': {
        'label': 'Authorization code',
        'placeholder': 'Authorization code'
      }
    },
    'feedback': {
      'proceed-ped-device': 'Please proceed payment on the PED device.',
      'no-response-ped-device': 'PED device did not respond.',
      'payment-success': 'Payment successful.',
      'authorization-declined': 'Authorization declined',
      'failure-unknown': 'Unknown error from PED device',
      'api-error': 'Error: Payment can not be added to booking, please retry',
      'manual-refund': 'Customer needs to be manually refunded, instructions for the support team can be printed. Please start the sales process again after the print.',
      'failure-code': {
        '4': 'Declined Online',
        '5': 'Declined Offline',
        '9': 'Cancelled',
        '10': 'Transaction performed',
        '16': 'Capture Card, declined online',
        '19': 'Transaction Aborted',
        '20': 'Pre sales completed',
        '21': 'Pre sales rejected',
        '22': 'Card number not matched',
        '23': 'Expiry date not matched',
        '24': 'Invalid transaction state',
        '25': 'Transaction not valid for requested operation',
        '26': 'Invalid PGTR',
        '27': 'Invalid Merchant',
        '28': 'Invalid Terminal',
        '29': 'Merchant status is not valid',
        '30': 'Invalid card number',
        '31': 'Expired Card',
        '32': 'Pre valid card',
        '33': 'Invalid issue number',
        '34': 'Invalid card expiry date',
        '35': 'Invalid start date',
        '36': 'Card not accepted',
        '37': 'Transaction not allowed',
        '38': 'Cash back not allowed',
        '42': 'Status Busy',
        '43': 'Status Not Busy',
        '44': 'Pinpad is not connected',
        '45': 'Pinpad is connected',
        '50': 'AVS details required'
      }
    },
    'issuer': {
      '000': 'Not In Use',
      '001': 'VISA DEBIT',
      '002': 'UK Electron',
      '003': 'Visa Purchasing',
      '004': 'Visa',
      '005': 'MasterCard',
      '006': 'UK Maestro',
      '007': 'Solo DISCONTINUED',
      '008': 'JCB',
      '009': 'Maestro',
      '010': 'VISA ATM',
      '011': 'ARVAL PHH',
      '012': 'Amex',
      '013': 'Diners Club International',
      '014': 'Laser',
      '015': 'DUET',
      '016': 'HN Mastercard',
      '017': 'HN PLCC',
      '018': 'Tesco Clubcard',
      '019': 'DANKORT',
      '020': 'Discover',
      '021': 'US Debit',
      '022': 'Debit MasterCard',
      '023': 'MasterCard Purchasing',
      '024': 'Bank of America',
      '080': 'YESpay Virtual Card',
      '081': 'Flexecash Love 2 Reward',
      '090': 'YESpay Gift Card'
    },
    'paymentMethod': {
      '0': '',
      '1': 'KEYED',
      '2': 'MAG.STRIPE',
      '5': 'ICC',
      '7': 'CONTACTLESS',
      '21': 'CASH',
      '81': 'KEYED',
      '91': 'CONTACTLESS-voucher',
      '92': 'CONTACTLESS'
    },
    'verification-type': {
      '1': 'SIGNATURE VERIFIED',
      '2': 'PIN VERIFIED',
      '7': 'CARDHOLDER NOT PRESENT',
      '8': 'NO CARDHOLDER VERIFICATION',
      '9': 'UNKNOWN CVM',
      '10': 'SIGNATURE AND PIN VERIFIED',
      '14': 'CONSUMER DEVICE VERIFICATION'
    },
    'receipt-error': 'Transaction receipt(s) could not be printed.',
    'receipt-signature-error': 'Signature receipt could not be printed',
    'abandon-operation': 'Print refund and start again',
    'amount-to-be-paid': 'Amount to be paid',
    'payment-received': 'Payment received',
    'retry': 'Retry',
    'retry-print': 'Retry print',
    'retry-signature-print': 'Retry signature print',
    'retry-ped-failed-print': 'Retry failed receipt',
    'validate': 'Validate',
    'decline': 'Decline'
  },
  'voucher-payment': {
    'default-error': 'Unknown error validating voucher',
    'display-change': 'Change',
    'display-amount-left': 'Amount left',
    'form': {
      'amount-to-be-paid': {
        'label': 'Amount to be paid'
      },
      'voucher-code': {
        'label': 'Voucher code',
        'placeholder': 'Voucher code'
      },
      'validate-voucher': {
        'label': 'Validate voucher'
      }
    }
  },
  'return-payment': {
    'title': 'Return payment',
    'already-paid': 'The client has already paid:',
    'payment-description': '{method, select, CASH {Cash} BOM_PED {Credit/debit card} voucher {Voucher {code}} other {Unknown:}}',
    'confirm-return': 'Please confirm that the payment(s) is/are returned to the customer',
    'confirm-button': 'Confirm'
  },
  'seat-selector': {
    'title': 'Seat selector',
    'description': 'Seat selector',
    'travel-direction': 'Travel direction',
    'confirm-discard-pending-changes': {
      'title': 'Discard pending seat selection',
      'information': 'Are you sure you wish to proceed? This will discard your pending seat selection.'
    },
    'segment-navigation': {
      'title': 'Segment navigation bar',
      'od': '{departureStation} - {arrivalStation}'
    },
    'carriage-selector': {
      'carriage-number': 'Carriage {carriageNumber}',
      'seats-available': '{seatsAvailable} seats available',
      'no-seats-available': '0 seats available'
    },
    'passenger-manifest': {
      'title': 'Passengers',
      'description': 'Passengers description',
      'passenger-number': 'Passenger {number}',
      'passenger-type': '({type, select, A {Adult} C {Child} S {Student}})',
      'passenger-seat': 'Coach {carriageNumber}, Seat {seatNumber}',
      'select-seat': 'Select a seat',
      'no-seat-selected': 'No seat selected yet'
    },
    'progress-navigation': {
      'assign-automatically': 'Assign automatically',
      'keep-selection': 'Keep current selection',
      'skip-leg': 'Skip this leg',
      'od': '{departureStation} - {arrivalStation}',
      'finish': 'Proceed with booking',
      'selections-left': '{selectionsLeft} {selectionsLeft, plural, one {seat} other {seats}} left to pick',
      'next-leg': 'Next leg',
      'previous-leg': 'Previous leg',
      'confirm': 'Confirm seat selection'
    },
    'abort': {
      'button': 'Exit seat selection',
      'confirm': {
        'title': 'Exit seat selection',
        'information': 'Not all seats relevant to your journey have been selected yet. Are you sure you wish to abort the seat selection and continue with your booking?'
      }
    },
    'unavailable-segments': {
      'information': 'On the following segments seat selection is not available: {segments}',
      'segment': '{departureStation} - {arrivalStation}'
    }
  },
  'cart': {
    'title': 'Cart',
    'description': 'Cart Description',
    'total-price': 'Total price',
    'to-be-paid': 'To be paid',
    'abort': 'Cancel',
    'remove-item': 'Remove cart item',
    'remove-voucher': 'Remove voucher',
    'payment-description': '{method, select, CASH {Cash:} BOM_PED {Credit/debit card:} voucher {Voucher:} other {Unknown:}}'
  },
  'shortcuts': {
    'empty': 'Empty slot',
    'title': 'Shortcuts',
    'description': 'ShortcutDescription',
    'edit-button': 'Edit shortcuts',
    'passenger-count': '{type, select, A {{count}A} C {{count}C} S {{count}S}}',
    'product-family': '{description}',
    'all-origin-destination': 'All',
    'destination': '{destinationName}',
    'discount-card-welfare': 'SW',
    'discount-card-privilege': 'P',
    'discount-card-student': 'S'
  },
  'finalize-booking-modal': {
    'printing-tickets': 'Ticket {number}/{total} is now printing...',
    'printing-voucher': 'Voucher {number}/{total} is now printing...',
    'change-fulfillment': 'Change fulfillment',
    'continue-printing': 'Continue Printing',
    'retry-printing': 'Retry Printing',
    'retry-receipt-printing': 'Retry receipt',
    'retry': 'Retry',
    'next-sale': 'Next sale',
    'next-customer': 'Next customer please',
    'open-aftersales': 'Open after-sales',
    'booking-details': 'Show booking details',
    'magstripe': {
      'title': 'Print ticket',
      'status-success': 'Magstripe successfully printed',
      'status-busy': 'Printing magstripe',
      'status-error': 'Error: {error}',
      'default-error': 'unknown error occurred during printing tickets'
    },
    'barcode': {
      'title': 'Print ticket',
      'status-busy': 'Printing barcode',
      'status-success': 'Barcode successfully printed',
      'status-error': 'Error: {error}',
      'default-error': 'Barcode could not be printed',
      'fetch-error': 'Barcode could not be retrieved for printing.'
    },
    'gift-voucher': {
      'title': 'Print gift voucher',
      'status-busy': 'Printing gift voucher',
      'status-success': 'Gift voucher(s) successfully printed',
      'status-error': 'Error: {error}',
      'default-error': 'Gift voucher could not be printed'
    },
    'leap': {
      'title': 'Scan Leap',
      'reading': 'Please present Leap card now...',
      'top-up-executing-leap': 'Top up executing, one moment please...',
      'top-up-success': 'Successfully executed top-up for Leap',
      'add-product-executing-leap': 'Add product executing, one moment please...',
      'add-product-success': 'Successfully executed add product for Leap',
      'error': {
        'amount-exceeded': 'The chosen top-up amount ({amount}) + current purse balance ({purseBalance}) exceeds the maximum allowed balance ({maximumTransactionValue}).',
        'amount-too-low': 'The chosen top-up amount ({amount}) + current purse balance ({purseBalance}) is below the minimum required balance ({minimumTransactionValue}).',
        'top-up-failed': 'The top-up has failed',
        'add-product-failed': 'The add product has failed'
      }
    },
    'receipt': {
      'default-error': 'Receipt could not be printed',
      'get-receipt-document-error': 'Receipt could not be retrieved from the server'
    },
    'receipt-printer': {
      'offline': 'Please note that the receipt printer is offline, so printing receipts is currently not possible'
    },
    'ticket-printer': {
      'offline': 'Ticket printer is offline'
    },
    'leap-device': {
      'offline': 'Leap device is offline'
    }
  },
  'product-family': {
    'more-button': 'More',
    'title-more-modal': 'More Product Families'
  },
  'taskbar': {
    'diagnostics': {
      'title': 'Warning!',
      'message': '{name}: {message}',
      'failure': 'NAD Manager did not respond'
    }
  },
  'machine': {
    'cash-drawer': {
      'not-responding': 'Cash drawer is not responding'
    },
    'customer-display': {
      'not-responding': 'Display is not responding',
      'lines': {
        'welcome': {
          'upper': 'Welcome',
          'lower': 'Fáilte'
        },
        'end-of-shift': {
          'upper': 'End of shift',
          'lower': 'Deireadh le haistriú'
        },
        'transaction-cancelled': {
          'upper': 'Transaction cancelled',
          'lower': 'Idirbheart ceal'
        },
        'payment-amount': {
          'upper': 'Amount / Méid',
          'lower': ''
        },
        'payment-display': {
          'cash': {
            'upper': 'Airgead/Cash',
            'lower': 'Briseadh/Change'
          },
          'credit-card': {
            'upper': 'Credit card / Cárta creidmheasa',
            'lower': 'No Change / Gan athrú'
          },
          'voucher': {
            'upper': 'Voucher / Dearbháin',
            'lower': {
              'amount-left': 'Amount left / Méid chlé',
              'change': 'Change / Athraigh'
            }
          }
        },
        'doubtful-transaction': {
          'upper': 'Please present Leap card again',
          'lower': 'Le do thoil a chur i láthair cárta Léime arís'
        }
      }
    },
    'receipt-printer': {
      'not-responding': 'Receipt printer is not responding',
      'paper-is-empty': 'Please refill receipt paper',
      'cover-is-open': 'Please close the receipt printer'
    },
    'ticket-printer': {
      'not-responding': 'Ticket printer is not responding',
      'running-low': 'Ticketbay is almost empty',
      'cover-is-open': 'Ticket printer is open'
    }
  },
  'booking-details': {
    'customer-details': {
      'title': 'Customer details',
      'description': 'Customer details description',
      'information': 'Please fill in your customer details',
      'required-fields': '* Required fields',
      'confirmation': 'Booking confirmation will be sent to this e-mail address',
      'form': {
        'customer-title': {
          'label': 'Title',
          'placeholder': 'Select',
          'is-required': 'This field is required'
        },
        'customer-first-name': {
          'label': 'First name',
          'placeholder': 'First name',
          'is-required': 'This field is required',
          'invalid-max-length': 'Invalid first name max length of 50 characters exceeded',
          'invalid-characters': 'No , allowed'
        },
        'customer-last-name': {
          'label': 'Last name',
          'placeholder': 'Last name',
          'is-required': 'This field is required',
          'invalid-max-length': 'Invalid last name max length of 50 characters exceeded',
          'invalid-characters': 'No , allowed'
        },
        'customer-email-address': {
          'label': 'E-mail address',
          'placeholder': 'E-mail address',
          'is-required': 'This field is required',
          'invalid-email-address': 'Invalid e-mail address',
          'invalid-max-length': 'Invalid e-mail address max length of 254 characters exceeded',
          'info': 'Booking confirmation will be sent to {emailAddress}.'
        },
        'customer-mobile-phone': {
          'label': 'Mobile phone',
          'placeholder': 'Mobile phone',
          'is-required': 'This field is required',
          'invalid-max-length': 'The phone number cannot have more than 50 characters',
          'invalid-phone-number': 'The phone number must be a valid international number'
        },
        'is-passenger': {
          'placeholder': '',
          'label': 'Customer is also a traveling passenger'
        },
        'invalid-form': 'A customer e-mail address or phone number is required.'
      }
    },
    'selected-customer': {
      'find-customer-placeholder': 'Find a customer in CRM',
      'find-customer-button': 'Find customer',
      'unlink-customer-button': 'Unlink customer',
      'selected-customer-name': 'Selected: {fullName} ({emailAddress})'
    },
    'find-customers-dialog': {
      'title': 'Find customer',
      'form': {
        'search': 'Find customer',
        'reset': 'Reset',
        'result-summary': '{count, number} {count, plural, one {Result} other {Results}} found',
        'term': {
          'is-required': 'This field is required',
          'placeholder': 'Search term',
          'label': 'Search term',
          'clear': 'Clear search term'
        },
        'category': {
          'is-required': 'This field is required',
          'options': {
            'first-name': 'First name',
            'last-name': 'Last name',
            'email-address': 'E-mail address',
            'phone-number': 'Phone number',
            'company-name': 'Company name',
            'street-name': 'Street',
            'house-number': 'House number',
            'postal-code': 'Postal code',
            'city': 'City'
          },
          'label': 'Category',
          'placeholder': 'Select category'
        }
      },
      'table': {
        'column-name': 'Customer',
        'column-email': 'E-mail address'
      },
      'no-search-description': 'To find a customer, enter a search term in the field above.'
    }
  },
  'passenger-details': {
    'title': 'Passenger details',
    'description': 'Passenger details description',
    'title-group': 'Group details',
    'description-group': 'Group details description',
    'passenger-type': '{type, select, A {Adult} C {Child} S {Student}}',
    'passenger-name-placeholder': 'Please provide the necessary passenger details.',
    'passenger-number': 'Passenger {number}',
    'cancelled': 'Cancelled',
    'group-name': 'Group name',
    'group-name-description': 'Group name',
    'required-fields': '* Required fields',
    'form': {
      'legend': {
        'address': 'Address',
        'contact-information': 'Contact information'
      },
      'equal': {
        'label': 'Passenger 1 is equal to the contact person',
        'placeholder': 'Passenger 1 is equal to the contact person'
      },
      'group-name': {
        'label': 'Group name',
        'placeholder': 'Group name',
        'is-required': 'This field is required',
        'invalid-characters': 'No , allowed'
      },
      'title': {
        'label': 'Title',
        'placeholder': 'Select',
        'is-required': 'This field is required'
      },
      'first-name': {
        'label': 'First name',
        'placeholder': 'First name',
        'is-required': 'This field is required',
        'invalid-max-length': 'Invalid first name max length of 50 characters exceeded',
        'invalid-characters': 'No , allowed'
      },
      'last-name': {
        'label': 'Last name',
        'placeholder': 'Last name',
        'is-required': 'This field is required',
        'invalid-max-length': 'Invalid last name max length of 50 characters exceeded',
        'invalid-characters': 'No , allowed'
      },
      'email-address': {
        'label': 'Email address',
        'placeholder': 'E-mail address',
        'is-required': 'This field is required',
        'invalid-email-address': 'This field must be a valid e-mail address',
        'invalid-max-length': 'Invalid e-mail address max length of 254 characters exceeded'
      },
      'phone-number': {
        'label': 'Phone number',
        'placeholder': 'Phone number',
        'is-required': 'This field is required',
        'invalid-max-length': 'The phone number cannot have more than 50 characters',
        'invalid-phone-number': 'The phone number must be a valid Irish or international number'
      },
      'protect-privacy': {
        'placeholder': '',
        'label': 'I do not want my name(s) displayed over my seat(s). Display my booking number instead!'
      },
      'continue': 'Next passenger'
    },
    'info': 'Your contact information will only be used to communicate about this booking.'
  },
  'bookings-filter': {
    'title': 'Booking list',
    'description': 'Booking list description',
    'form': {
      'clear-term': 'Clear search terms',
      'search-bookings': 'Find bookings',
      'results-found': '{number} Results found',
      'reset': '(reset)',
      'term': {
        'label': 'Search term',
        'placeholder': 'Search term'
      },
      'categories': {
        'label': 'Category',
        'placeholder': 'Search category'
      }
    },
    'categories': {
      'booking-number': 'Booking reference',
      'customer-name': 'Customer name',
      'customer-email': 'Customer email',
      'customer-company': 'Company name',
      'passenger-name': 'Passenger name',
      'passenger-email': 'Passenger email'
    }
  },
  'bookings-results': {
    'table': {
      'no-results': 'No results found',
      'no-route': 'N/A',
      'headers': {
        'customer': 'Customer',
        'outward-date': 'Outward date',
        'route': 'Route',
        'booked': 'Booked',
        'reference': 'Reference',
        'status': 'Status',
        'product-description': 'Product description',
        'price': 'Price',
        'pax': 'Amount of pax'
      }
    }
  },
  'booking': {
    'title': 'Booking {bookingNumber}',
    'description': 'Booking {bookingNumber} description',
    'ticket-collection-number': 'Ticket collection number',
    'booking-number-text': 'Booking number',
    'booking-status': 'Booking status',
    'process-booking': 'Process booking',
    'toolbar': {
      'override-aftersales': 'This action is not allowed according to after sales rules. Are you sure you wish to continue?',
      'override-validation': 'This action is not allowed according to ticket validation rules. Are you sure you wish to continue?',
      'aria-label': 'After sales action buttons',
      'back-homepage': 'Back to home',
      'change-fulfillment': 'Change fulfillment',
      'rebooking': 'Change booking',
      'change-seats': 'Change seat',
      'update-passengers': 'Change passenger',
      'cancellation': 'Cancel',
      'additional-products': 'Add extras',
      'book-again': 'Book again',
      'manual-confirm': 'Confirm booking'
    },
    'provisional-timer': {
      'expire-in': 'Expire in {days, plural,  =0 {} one {1 day} other {{days} days}} {hours}:{minutes}:{seconds}',
      'is-expired': 'Expired'
    }
  },
  'booking-status': {
    'confirmed': 'Confirmed',
    'provisional': 'Provisional',
    'cancelled': 'Cancelled',
    'on-hold': 'On hold',
    'expired': 'Expired'
  },
  'aftersales': {
    'operation': {
      'abort-button': 'Abandon operation',
      'booking-number': 'Booking number'
    },
    'refund-modal': {
      'title': 'Refund',
      'cancel': 'Abort refund',
      'table': {
        'method': 'Method',
        'amount': 'Amount'
      },
      'steps': {
        'overview': 'Overview',
        'cash': 'Cash',
        'ped': 'Creditcard',
        'voucher': 'Voucher'
      },
      'overview': {
        'ped-before-cash': 'Please handle Creditcard refund before returning Cash',
        'start': 'Start refund'
      },
      'cash': {
        'refund-description': 'Please refund {amount} with cash',
        'confirm-payout': 'Cash is payed out'
      },
      'voucher': {
        'refund-description': '{amount} will be refunded by voucher',
        'confirm': 'Refund'
      },
      'creditcard': {
        'success': 'Refund completed',
        'retry': 'Retry',
        'retry-refund-print': 'Retry refund print',
        'refund-description': '{amount} will be refunded by creditcard. Control is now at Pin Entry Device.',
        'confirm': 'Confirm'
      }
    },
    'rebooking': {
      'header': {
        'title': 'Change your booking',
        'information': 'You can use this screen to modify your booking. Please note the price difference between the original booking and the new booking will be displayed at the end the transaction. Subject to terms and conditions'
      },
      'information': 'You can use this screen to modify your booking. Please note the price difference between the original booking and the new booking will be displayed at the end the transaction. Subject to terms and conditions.',
      'outbound': '{originName} - {destinationName}',
      'inbound': '{originName} - {destinationName}',
      'both': 'Both',
      'confirmation-success': 'Your booking has been rebooked successfully',
      'selection-description': 'Select which part of the journey you would like to rebook',
      'passengers-summary': 'You are rebooking for {valueAdult} {valueAdult, plural, =0 {} one {Adult} other{Adults}} {valueStudent} {valueStudent, plural, =0 {} one {Student} other{Students}} {valueChild} {valueChild, plural, =0 {} one {Child} other{Children}}',
      'no-booking-fulfillment-method': 'The product added to the booking can not be fulfilled using the bookings original fulfillment method, please abandon the current aftersales operation'
    },
    'additional-products': {
      'description': 'You can use this screen to add additional products for your journey',
      'confirm': 'Confirm',
      'confirm-sub-pay': 'Go to payment',
      'cancel': 'Cancel',
      'header': {
        'title': 'Additional products',
        'information': 'You can use this screen to add additional products for your journey.'
      }
    },
    'change-fulfillment': {
      'confirm': 'Confirm',
      'header': {
        'title': 'Change fulfillment',
        'information': 'You can use this screen to change the fulfillment.'
      }
    },
    'update-passengers': {
      'confirm': 'Confirm',
      'confirm-sub-pay': 'Go to payment',
      'header': {
        'title': 'Update passengers',
        'information': 'You can use this screen to change passengers.'
      }
    },
    'header': {
      'booking-number': 'Booking number',
      'abort-button': 'Abandon operation'
    },
    'waive-fees': {
      'title': 'Waive fees',
      'description': 'Waive fees',
      'information': 'You are authorized to waive fees for this booking. Click here to perform the operations while skipping the application of possibly configured fees.',
      'label': 'I would like to waive fees for this booking',
      'apply-waive-fees': 'Apply waive fees',
      'unapply-waive-fees': 'Unapply waive fees'
    },
    'change-seats': {
      'information': 'You can use this screen to change your seat selection.',
      'confirmation-success': 'Your seats have been allocated successfully',
      'header': {
        'title': 'Change your seat',
        'information': 'You can use this screen to change your seat selection.'
      },
      'confirm': 'Confirm',
      'confirm-sub-pay': 'Go to payment',
      'footer': {
        'assign-automatically': 'Assign automatically',
        'keep-selection': 'Keep current selection',
        'skip-leg': 'Skip this leg',
        'od': '{departureStation} - {arrivalStation}',
        'finish': 'Proceed with booking',
        'selections-left': '{selectionsLeft} {selectionsLeft, plural, one {seat} other {seats}} left to pick',
        'next-leg': 'Next leg',
        'previous-leg': 'Previous leg',
        'confirm': 'Confirm seat selection'
      }
    },
    'cancellation': {
      'header': {
        'title': 'Cancel part or all of your booking',
        'information': 'You can use this screen to cancel your booking. You will be shown the refund amount you are entitled to when you have completed the process.'
      },
      'confirmation-success': 'Your tickets have been cancelled successfully',
      'passenger-unknown-name': 'Passenger {number}',
      'individual-items': {
        'title': 'Cancel individual ticket items',
        'description': 'Cancel individual ticket items',
        'cancelled': '(Cancelled)',
        'segment-set-header': {
          'date': 'on {date}',
          'direction': '{departureStation} to {arrivalStation}'
        },
        'status-indicator-operation': 'Will be cancelled',
        'status-indicator-confirm': 'Cancel'
      },
      'cancel-by': {
        'title': 'Cancel by',
        'description': 'Cancel by',
        'outbound-warning': 'Please note that all outward tickets will be cancelled for all passengers, unless indicated otherwise below by selecting a specific passenger.',
        'inbound-warning': 'Please note that all return tickets will be cancelled for all passengers, unless indicated otherwise below by selecting a specific passenger.',
        'passenger-warning': 'Please note that all tickets will be cancelled for the selected passenger(s), unless a specific direction is selected above.',
        'form': {
          'legend': {
            'entire-journey': 'Entire booking',
            'direction': 'Direction',
            'passenger': 'Passenger'
          },
          'entire-booking': {
            'label': 'Cancel entire booking'
          },
          'outbound': {
            'label': 'Outward'
          },
          'inbound': {
            'label': 'Return'
          },
          'passenger': {
            'label': '{firstName} {lastName} {type, select, C {(Child)} S {(Student)} other {}}'
          }
        }
      },
      'refund-details': {
        'next-step': 'On the next step the total refund details will be shown',
        'title': 'Refund details',
        'description': 'Refund details',
        'ticket-value': 'Ticket value',
        'fee': 'Cancel fee',
        'total': 'Total refund'
      },
      'navigation': {
        'next': 'Proceed',
        'next-sub': 'Calculate refund amount',
        'previous': 'Previous',
        'previous-sub': 'Back to selection',
        'confirm': 'Confirm',
        'confirm-sub-refund': 'And refund {total}',
        'confirm-sub-pay': '{amount, select, no {Go to payment} other {And pay {amount}}}'
      },
      'refund-summary': {
        'ticket-value': 'Tickets to be cancelled',
        'fee': 'Cancellation fees',
        'fee-placeholder': 'Fees may apply; see the last screen for more details'
      },
      'confirmation': {
        'information': 'Click \'confirm\' to confirm your cancellation.'
      },
      'reverse-leap': {
        'title': 'Reverse leap',
        'description': 'Reverse leap description',
        'start': 'Start',
        'retry': 'Retry',
        'information': 'The booking is made with a Leap card. This transaction needs to be reversed before the cancellation can be confirmed',
        'present': 'Please present Leap card now...',
        'read': 'Reading leap card...',
        'reverse-executing': 'Reverse executing, one moment please...',
        'can-override-cancellation': 'Skipping this step means not removing top up from the Leap card',
        'reverse-add-product-warning': 'The BOM cannot remove products from Leap card',
        'error': {
          'reverse-failed': 'The reverse has failed',
          'cancel-time-exceeded': 'Cancel time has been exceeded',
          'wrong-last-transaction-code': 'Last event is not top-up. Cannot revert product.',
          'wrong-card-presented': 'The presented Leap card is not for this booking. Please check the booking and try again',
          'booking-has-no-information': 'Unable to void, booking not fulfilled.',
          'wrong-last-event-value': 'Last event is top up, but contains another top up amount than booking.'
        },
        'success': 'Successfully reversed the last transaction for Leap'
      }
    },
    'void-tickets': {
      'header': {
        'title': 'Void tickets',
        'information': 'You can use this screen to void tickets'
      },
      'title': 'Void ticket',
      'description': 'Void ticket description',
      'start': 'Start',
      'retry': 'Retry',
      'information': 'One or more of the selected tickets are fullfilled by magstripe. These tickets need to be voided before continuing',
      'voiding-not-possible-within-time': 'There is not enough time left to finish this after sales operation. Another user with the right to overrule after sales rules must perform this action.',
      'present': 'Please present ticket {number}/{total}',
      'read': 'Awaiting ticket input...',
      'void': 'Voiding ticket...',
      'eject': 'Ejecting ticket...',
      'can-override-void-tickets': 'Are you sure you want to use override rules?',
      'override-rights-used': 'Override rights were used to skip the voiding of tickets',
      'error': {
        'could-not-read': 'No ticket provided or could not read ticket',
        'wrong-ticket': 'Wrong ticket provided. Please check if you presented the correct ticket and try again',
        'could-not-void': 'The ticket could not be voided. Please check the ticket and try again'
      },
      'success': 'All tickets are voided successfully. Please continue.'
    },
    'booking': {
      'payment-details': {
        'title': 'Payment details',
        'description': 'Payment details description',
        'discount-voucher-used': 'Discount voucher was used. Please click on the Booking details\' information icon(s) to see details.',
        'choose-refund': 'You are entitled to a refund. Choose your refund method below.',
        'refund-requested': 'Refund requested successfully',
        'refund-error': 'Refund could not be completed',
        'refund-requested-voucher': 'Refund requested successfully. Voucher code: {voucherCode}',
        'no-payment-details-found': 'There is no payment information available.',
        'payment-method': 'Payment method',
        'reference': 'Reference',
        'payment-status': 'Status',
        'amount': 'Amount',
        'status': {
          'confirmed': 'Successful',
          'provisional': 'Provisional',
          'failed': 'Failed',
          'not-yet-processed': 'Not yet processed',
          'unknown': 'Unknown'
        },
        'footer': {
          'refund-payment': 'Refund payment',
          'refund-by-voucher': 'Refund by voucher'
        },
        'header': {
          'payment-method': 'Payment method',
          'reference': 'Reference',
          'status': 'Status',
          'amount': 'Amount'
        }
      },
      'related-vouchers': {
        'title': 'Related vouchers',
        'description': 'Related vouchers description',
        'no-related-vouchers-found': 'No related vouchers found',
        'print': 'Print refund vouchers',
        'header': {
          'type': 'Type',
          'code': 'Code',
          'amount': 'Amount'
        },
        'refund-voucher-modal': {
          'cancel': 'Cancel print',
          'finish': 'Finish',
          'retry': 'Retry',
          'title': 'Print refund voucher',
          'printing': 'Printing voucher...',
          'success': 'Refund voucher successfully printed',
          'status-error': 'Error: {error}',
          'default-error': 'Refund voucher could not be printed'
        }
      },
      'agent': {
        'header': {
          'description': 'Agent details description',
          'title': 'Agent details'
        },
        'name': 'Booked by',
        'office': 'Office',
        'contract': 'Contract',
        'saleschannel': 'Saleschannel',
        'affiliate': 'Affiliate'
      },
      'customer': {
        'header': {
          'description': 'Customer details description',
          'title': 'Customer details'
        },
        'name': 'Name',
        'email': 'Email',
        'address': 'Address',
        'phone': 'Phone',
        'city': 'City',
        'country': 'Country',
        'postal-code': 'Postal Code'
      },
      'journey-overview': {
        'passenger': 'Passenger',
        'passenger-type': '({type, select, A {Adult} S {Student} C {Child} other {}})',
        'seat': 'Seat {number}',
        'coach': 'Coach {carriage}',
        'table': {
          'columns': {
            'passenger': 'Passenger',
            'seat': 'Seat',
            'add-ons': 'Add-ons',
            'price': 'Price'
          },
          'footer': 'Cancellation price'
        },
        'fees_description': 'Applicable fees',
        'header': {
          'title': 'Booking details',
          'description': 'Booking details description'
        },
        'segment-product-set': {
          'count': '{count}x'
        },
        'segment-set-header': {
          'date': 'on {date}',
          'direction': '{direction, select, inbound {Inward journey} outbound {Outward journey}}',
          'check-box-label': 'Show cancelled items'
        },
        'price-details-tooltip': {
          'product': 'Product',
          'tariff': 'Tariff',
          'price': 'Price',
          'discount': 'Discount',
          'total-passenger': 'Segment total for {name}',
          'total-discount': 'Total discount'
        }
      },
      'modal': {
        'title': 'Add note',
        'cancel': 'Cancel',
        'save': 'Save',
        'queue': {
          'label': 'Booking queue',
          'placeholder': 'Select booking queue',
          'call-back': 'Call-back',
          'complaint': 'Complaint',
          'refund': 'Refund',
          'status-format': '{bookingQueue}, {resolved, plural, =1 {Resolved} other {Open}}'
        },
        'content': {
          'label': 'Content',
          'placeholder': 'Note content'
        }
      },
      'reprint-resend': {
        'title': 'Fulfillment method ({fulfillment})',
        'description': 'Fulfillment method description',
        'no-reprint-resend-available': 'No reprint/resend available for this booking',
        'button': {
          'reprint-resend': 'Reprint/Resend'
        },
        'void-tickets': {
          'title': 'Void ticket',
          'description': 'Void ticket description',
          'start': 'Start',
          'retry': 'Retry',
          'cancel': 'Cancel',
          'reprint-tickets': 'Reprint Tickets',
          'information': 'One or more of the selected tickets are fullfilled by magstripe. These tickets need to be voided before the the tickets can be reprinted',
          'present': 'Please present ticket {number}/{total}',
          'read': 'Awaiting ticket input...',
          'void': 'Voiding ticket...',
          'eject': 'Ejecting ticket...',
          'override-rights-used': 'Override rights were used to reprint a product without voiding',
          'error': {
            'could-not-read': 'No ticket provided or could not read ticket',
            'wrong-ticket': 'Wrong ticket provided. Please check if you presented the correct ticket and try again',
            'could-not-void': 'The ticket could not be voided. Please check the ticket and try again'
          },
          'success': 'All tickets are voided successfully. Please proceed the reprint of the ticket(s).'
        }
      },
      'notes': {
        'title': 'Booking notes',
        'description': 'Booking notes description',
        'saved-note': 'Note saved successfully',
        'no-notes-found': 'No notes have been found',
        'header': {
          'date': 'Date',
          'agent': 'Agent',
          'status': 'Status',
          'note': 'Note'
        },
        'modal': {
          'title': 'Add note',
          'cancel': 'Cancel',
          'save': 'Save',
          'queue': {
            'label': 'Booking queue',
            'placeholder': 'Select booking queue',
            'call-back': 'Call-back',
            'complaint': 'Complaint',
            'refund': 'Refund',
            'status-format': '{bookingQueue}, {resolved, plural, =1 {Resolved} other {Open}}'
          },
          'content': {
            'label': 'Content',
            'placeholder': 'Note content'
          }
        }
      }
    },
    'read-ticket': {
      'title': 'Read ticket',
      'description': 'Read ticket description',
      'please-present-ticket': 'Please present ticket',
      'explanation': 'Please select your ticket to read to display the information about the booking',
      'ticket-not-read': 'The ticket could not be read. Please try again',
      'ticket-read': 'Ticket successfully read',
      'select-fulfillment-first': 'Please select a fulfillment',
      'default-error': 'Barcode could not be read or invalid barcode presented',
      'button': {
        'read-ticket': 'Read ticket',
        'eject-ticket': 'Eject ticket',
        'go-to-booking': 'Manage Booking'
      },
      'output': {
        'title': 'Read {fulfillment} ticket',
        'description': 'Read ticket output description'
      },
      'magstripe-data': {
        'ticket-header': 'Ticket Information',
        'validation-header': 'Validation Information',
        'technical-header': 'Technical-data',
        'machine-of-issue': 'Machine of Issue',
        'checksum': 'Checksum',
        'last-year-of-validity': 'Last year of validity',
        'last-day-of-validity': 'Last day of validity',
        'modal-restrictions': 'Modal restrictions',
        'ticket-type': 'Ticket type',
        'days-of-week-available': 'Days of week available',
        'number-of-journeys-allowed': 'Number of journeys allowed',
        'number-of-journeys-taken': 'Number of journeys taken',
        'time-of-day-valid-table': 'Time of day valid table',
        'passenger-status': 'Passenger status',
        'checksum-primary-data': 'Checksum primary data',
        'start-of-validity-validation-year': 'Start of validity year',
        'start-of-validity-validation-day': 'Start of validity day',
        'suburban-access-and-transfer': 'Suburban access and transfer',
        'previous-mode-of-travel': 'Previous mode of travel',
        'validation-time': 'Validation time',
        'number-of-road-validations': 'Number of road validations',
        'number-of-ptm-validations': 'Number of ptm validations',
        'feeder-trips-taken': 'Feeder trips taken',
        'boarding-station': 'Boarding station',
        'destination-station': 'Destination station',
        'ticket-serial-number': 'Ticket serial number'
      },
      'barcode-data': {
        'booking-number': 'Booking Number',
        'ticket-number': 'Ticket Number',
        'create-date': 'Create Date',
        'update-date': 'Update Date',
        'cancel-date': 'Cancel Date',
        'departure-station': 'Departure Station',
        'arrival-station': 'Arrival Station',
        'validity-start-date': 'Validity Start Date',
        'validity-end-date': 'Validity End Date',
        'validity-weekdays': 'Validity Weekdays',
        'validity-count': 'Validity Count',
        'validity-bi-directional': 'Validity Bi Directional',
        'validity-service': 'Validity Service',
        'coach': 'Coach',
        'seat-number': 'Seat Number',
        'product-code': 'Product Code',
        'tariff-code': 'Tariff Code',
        'inventory-class': 'Inventory Class',
        'comfort-zone': 'Comfort Zone',
        'price': 'Price',
        'currency': 'Currency',
        'payment-method': 'Payment Method',
        'sold-by': 'Sold By',
        'sales-channel-code': 'Sales Channel Code',
        'service-conflict': 'Service Conflict',
        'material-conflict': 'Material Conflict',
        'connection': {
          'arrival-station': 'Connection arrival station',
          'departure-station': 'Connection departure station',
          'departure-time': 'Connection departure time',
          'validity-service': 'Connection validity service',
          'ticket-number': 'Connection ticket number'
        },
        'passenger-first-name': 'Passenger First Name',
        'passenger-last-name': 'Passenger Last Name',
        'passenger-email': 'Passenger Email',
        'passenger-phone-number': 'Passenger Phone Number',
        'passenger-type': 'Passenger Type',
        'additional-products': {
          'name': 'Additional Products',
          'type': {
            'BK': 'Bike',
            'PR': 'Pram',
            'DG': 'Dog',
            'LU': 'Luggage',
            'FB': 'Food & Beverages',
            'FE': 'Fee',
            'VC': 'Voucher',
            'IN': 'Insurance',
            'ET': 'External ticket',
            'DS': 'Disability support',
            'default': 'Unknown'
          }
        },
        'validation-details': 'Validation Details',
        'exists': 'Yes',
        'not-exists': 'No',
        'header': {
          'validations': 'Validations'
        },
        'validations': {
          'date-time': 'Date time',
          'light-validation': 'Light validation',
          'location': 'Location',
          'steward': 'Steward',
          'ticket-number': 'Ticket number',
          'uid': 'Validation UID',
          'validation-type': 'Type'
        }
      }
    }
  },
  'voucher-to-cash': {
    'title': 'Search vouchers',
    'description': 'Search vouchers description',
    'no-vouchers': 'No vouchers could be found that match your search criteria. Please try again',
    'form': {
      'search': 'Find vouchers',
      'code': {
        'label': 'Search code',
        'placeholder': 'code'
      }
    },
    'table': {
      'pay-out': 'Pay out',
      'header': {
        'voucher-code': 'Voucher code',
        'type': 'Type',
        'description': 'Description',
        'refund-value': 'Value',
        'pay-out': 'Pay out'
      }
    },
    'modal': {
      'title': 'Voucher to cash',
      'information': 'Are you sure you wish to pay out this voucher in cash?',
      'voucher-refunded': 'Voucher has been refunded',
      'voucher-code': 'Voucher code',
      'type': 'Type',
      'amount-to-pay': 'Amount to pay out',
      'default-error': 'Voucher could not be refunded',
      'button': {
        'pay-out': 'Pay out',
        'cancel': 'Cancel',
        'next-sale': 'Next sale'
      }
    }
  },
  'maintenance': {
    'test-cfd': {
      'title': 'Test customer facing display',
      'description': 'Test customer facing display',
      'loading': 'Sending request to display...',
      'test-display': {
        'error': 'Display not responding',
        'success': 'Test executed succesfully. Please examine the display'
      },
      'clear-display': {
        'error': 'Can not clear display'
      },
      'button': {
        'clear': 'Clear display',
        'retry': 'Retry'
      }
    },
    'test-thermal-printer': {
      'title': 'Test thermal printer',
      'description': 'Test thermal printer',
      'loading': 'Printing test receipt...',
      'feedback': {
        'error': 'Printer not ready',
        'success': 'Test executed succesfully. Please examine the print'
      },
      'retry': 'Retry'
    },
    'set-darkness': {
      'title': 'Set magstripe darkness',
      'description': 'Darkness...',
      'loading': 'loading',
      'feedback': {
        'error': 'error',
        'success': 'Darkness Level is now {darknessLevel}'
      },
      'button': {
        'decrease': 'Decrease darkness',
        'increase': 'Increase darkness'
      }
    }
  },
  'read-ticket': {
    'days-of-week': {
      'monday': 'Monday',
      'tuesday': 'Tuesday',
      'wednesday': 'Wednesday',
      'thursday': 'Thursday',
      'friday': 'Friday',
      'saturday': 'Saturday',
      'sunday': 'Sunday'
    },
    'magstripe': {
      'mode-of-travel': {
        'new': 'New',
        'local-link': 'Local Link',
        'feeder-bus': 'Feeder Bus',
        'bus': 'Bus',
        'suburban-rail': 'Suburban Rail',
        'mainline-rail': 'Mainline Rail'
      },
      'modal-restrictions': {
        'bus-eireann': 'Bus Eireann (E)',
        'mainline-rail': 'Mainline Rail (I)',
        'suburban-rail': 'Suburban Rail (D)',
        'dublin-bus': 'Dublin Bus (B)',
        'feeder-bus': 'Feeder Bus (F)'
      },
      'suburban': {
        'access': {
          'allowed': 'Suburban transfer is allowed',
          'not-allowed': 'Suburban transfer is not allowed'
        },
        'transfer': {
          'allowed': 'Access to Controlled Area is allowed',
          'not-allowed': 'Access to Controlled Area is not allowed'
        }
      }
    }
  },
  'progress-bar': {
    'seat-selection': 'Seats',
    'additional-products': 'Extras',
    'details': 'Customer details',
    'payment': 'Review & Pay'
  },
  'leap': {
    'read': {
      'title': 'Leap card',
      'title-card-number': 'Card no: {cardNumber}',
      'description': 'Leap card description',
      'read-leap-card': 'Read leap card',
      'read-new-leap-card': 'Read new leap card',
      'reverse-leap-card': 'Revert tag on',
      'please-present-ticket': 'Please present Leap card now...',
      'add-product': 'Add product',
      'top-up': 'Top up'
    },
    'leap-reverse-modal': {
      'title': 'Revert tag on',
      'cancel': 'Cancel',
      'retry': 'Retry',
      'finish': 'finish',
      'reverse-leap-card': 'Revert tag on',
      'please-present-card': 'Please present Leap card now...',
      'reading': 'Please present Leap card now...',
      'revert-executing-leap': 'revert tag on executing, one moment please...',
      'revert-success': 'Successfully executed revert tag on for Leap',
      'tag-on-failed': 'Reverse tag on failed'
    },
    'data': {
      'title': 'Card no: {cardNumber}',
      'description': 'Leap card data description',
      'purse-top-up': 'Purse top up',
      'reverse-event': 'Reverse event',
      'leap-card-not-read': 'Leap card could not be read',
      'card-expired-since': 'Card is expired since {date}',
      'leap-card-is-blocked': 'Card is blocked',
      'leap-purse-is-blocked': 'Purse is blocked',
      'no-leap-configuration': 'No Leap configuration found, please restart your shift to retrieve the Leap configuration data.',
      'leap-invalid-product-configuration': 'The product selected is not correctly configured',
      'leap-configuration-data-corrupt': 'The leap configuration data is corrupt. Please restart your shift.',
      'leap-doubtful-transaction': 'Doubtful transaction detected. Please present Leap card again and tap \'Retry\' to verify.',
      'leap-invalid-profile-restrictions': 'This product can\'t be added due to profile restrictions',
      'leap-present-correct-card': 'Please present correct Leap card',
      'leap-transaction-period-expired': 'Transaction period has expired.',
      'output': {
        'header': {
          'card': 'Card',
          'purse': 'Purse',
          'last-event': 'Last event'
        },
        'card': 'Card',
        'card-identifier': 'Number',
        'card-profile': 'Profile',
        'issuer-identifier': 'Issuer',
        'issuer-leap-card-name': 'Leap Card',
        'issuer-dsp-psc-card-name': 'DSP/PSC Card',
        'issuer-identifier-unknown': 'Unknown',
        'card-status': 'Status',
        'expiry-date': 'Expiry date',
        'purse': 'Purse',
        'purse-balance': 'Balance',
        'purse-status': 'Status',
        'min-max-top-up': 'Min/max top-up',
        'last-transaction': {
          'event-code': 'Event code',
          'transit-event-code': 'Transit Event Code',
          'value': 'Value',
          'payment-method': 'Payment method',
          'reason-code': 'Reason code',
          'date-time': 'Date'
        }
      }
    },
    'products': {
      'title': 'Products on this card',
      'description': 'Products on this card description',
      'product-name': 'Product {number}',
      'no-products-found': 'No products found on the card',
      'data': {
        'class': 'Class',
        'type': 'Type',
        'usn': 'USN',
        'owner-id': 'Owner ID',
        'soac': 'SOAC',
        'issue-date-time': 'Issue date',
        'expiry-date-time': 'Expiry date',
        'start-date-time': 'Start date',
        'status': 'Status',
        'block-date-time': 'Block date',
        'activation-date-time': 'Activation date',
        'journey-balance': 'Journey balance',
        'issue-participant-id': 'Issue participant ID',
        'price': 'Price',
        'payment-method': 'Payment method',
        'origin': 'Origin',
        'destination': 'Destination',
        'journey': 'Journey',
        'route': 'Route',
        'service': 'Service'

      }
    }
  },
  'terms-and-conditions': {
    'link': 'Terms & Conditions',
    'title': 'Terms & Conditions'
  }
}
